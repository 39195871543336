<template>
  <div id="book_list_zh">
    <div
        class="books_content"
        v-for="(item, index) in dataLists"
        :key="index"
        @click="onLineClick(item)"
        @touchstart="touchin(item)"
        @touchend="cleartime(item)">
      <div class="books_left aqt_ce_ve">
        <img class="img" v-lazy="item.img" alt="" />
        <div v-show="item.annexcount>0" class="label">PDF</div>
      </div>
      <div class="books_right">
        <div class="name">
          {{ item.titlezh }}
        </div>
        <div class="content" v-html="htmlFilter(item.contentzh)"></div>
        <div class="other">
          <div class="left" v-if="item.author">
            <div class="icon">
              <img src="@/assets/img/info_people.png" alt="" />
            </div>
            <div class="othername">{{ item.author }}</div>
          </div>
          <div class="left" v-if="item.press_name">
            <div class="icon">
              <img src="@/assets/img/info_book.png" alt="" />
            </div>
            <div class="othername">{{ item.press_name }}</div>
          </div>
        </div>
      </div>
    </div>
    <NoData v-if="isLoading && !dataLists.length"></NoData>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: [Array],
      default: [],
    },
    isLoading: {
      type: [Boolean],
      default: false,
    },
  },
  components: {
    NoData: (resolve) => {require(["@/components/NoData.vue"], resolve);},
  },
  data() {
    return {
      dataLists:[]
    };
  },
  methods: {
    onLineClick(data) {
      const _this = this;
      _this.$emit("on-line-click", data);
    },
    touchin(data) {
      const _this = this;
      _this.$emit("on-touchin", data);
    },
    cleartime(data) {
      const _this = this;
      _this.$emit("on-cleartime", data);
    },



  },
  watch: {
    dataList: function (val) {
      this.dataLists = val;
    },
  },
};
</script>

<style lang="less">
#book_list_zh {
  margin-top: 5px;
  overflow: auto;
  .books_content {
    padding: .8rem;
    margin-bottom: 2px;
    background-color: #fff;
    height: 14.6rem;
    display: flex;
    .books_left {
      width: 10rem;
      height: 13rem;
      overflow: hidden;
      position: relative;
      .label {
        line-height: 19px;
        height: 16px;
        background: red;
        color: #fff;
        font-size: 10px;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        padding: 0 15px;
        -webkit-transform-origin: left bottom;
        -moz-transform-origin: left bottom;
        transform-origin: left bottom;
        -webkit-transform: translate(29.29%, -100%) rotate(45deg);
        -moz-transform: translate(29.29%, -100%) rotate(45deg);
        transform: translate(29.29%, -100%) rotate(45deg);
        text-indent: 0;
      }
      .img{
        width: 100%;
        height: 100%;
      }
      img[lazy="loading"]{
        height: auto!important;
      }
    }
    .books_right {
      width: calc(100% - 10rem);
      height: 100%;
      padding-left: 1rem;
      .name{
        height: 3rem;
        width: 100%;
        line-height: 3rem;
        font-size: 1.6rem;
        font-weight: bolder;
        white-space:nowrap;
        -o-text-overflow:ellipsis;
        text-overflow:ellipsis;
        overflow: hidden;
      }
      .content{
        height: calc(100% - 9rem);
        font-size: 1.4rem;
        line-height: 2rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #909090;
        margin-bottom: 3rem;

      }
      .other{
        height: 4rem;
        width: 100%;
        line-height: 4rem;
        display: flex;
        .left{
          width: 50%;
          overflow: hidden;
          display: flex;
          .icon{
            width: 2rem;
            img{
              width: 1.6rem;
            }
          }
          .othername{
            width: calc(100% - 2rem);
            font-size: 1.3rem;
          }
        }
      }

    }
  }
  .nomassage {
    padding: 0 1rem;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    display: -ms-flexbox;
    display: flex;
    font-size: 2rem;
  }
}
</style>
